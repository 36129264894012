/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ya falta menos para el verano y para amenizar la espera he estado leyendo comics de Star Wars, si a parte de Geek soy friki de Star Wars. Como está un poco complicado encontrar las series completas de comics he recopilado un par y las teneis en descarga directa, la primera es ", React.createElement(_components.a, {
    href: "http://www.megaupload.com/?d=9L2ZNPEQ"
  }, "Imperio Oscuro"), " que fue la primera serie de comics de Star Wars que se publicó, trata de los años siguientes a El retorno del Jedi cuando el imperio resurge con la resurección del Emperador Palpatine, la otra es una ya mas moderna, ", React.createElement(_components.a, {
    href: "http://www.megaupload.com/?d=RH2OGUB2"
  }, "Legado"), ", transcurre 100 años despues de lo anterior donde el panorama de la galaxia a cambiado totalmente y un descendiente de los Skywarkers tiene que volver a enfrentarse al resurgimiento de los Sith."), "\n", React.createElement(_components.p, null, "Para leer estos comics en linux podeis utilizar el programa Comix, ya que estan en formato cbr."), "\n", React.createElement(_components.p, null, "Espero que os gusten. Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
